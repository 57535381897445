import { Injectable } from '@angular/core';
import { ServicioAbstract } from 'src/app/common/services/service.service';
import { ArqueoCaja } from 'src/app/model/ArqueoCaja';
@Injectable({
    providedIn: 'root',
})
export class ArqueoCajaService extends ServicioAbstract<ArqueoCaja>{
    public baseName(): string {
        return "arqueo-caja";
    }
    public parseToEnt(data: any): ArqueoCaja {
        return ArqueoCaja.fromData(data);
    }
    public newEnt(): ArqueoCaja {
        return new ArqueoCaja();
    }


}