import { Descriptivo } from 'src/app/common/model/Descriptivo';
import { Moneda } from 'src/app/model/Moneda';
import { ComprobanteResumen } from './Comprobante';






export class ItemCobranza extends Descriptivo {
    constructor(id?: number, codigo?: string, descripcion?: string,
        public comprobante?: ComprobanteResumen,
        public importe?: number,
        public moneda?: Moneda,
        public cotizacion: number = 1


    ) {
        super(codigo, descripcion, id)
    }

    public static fromData(data: any): ItemCobranza {
        if (!data) return null;
        const o: ItemCobranza = new ItemCobranza(
            data.id,
            data.codigo,
            data.descripcion,

            ComprobanteResumen.fromData(data.comprobante),
            data.importe,
        );

        return o;

    }

}
