import { Injectable } from '@angular/core';
import { ParametricoService } from 'src/app/common/services/parametricos.service';
import { FormaPago } from 'src/app/model/FormaPago';
@Injectable({
    providedIn: 'root',
})
export class FormaPagoService extends ParametricoService<FormaPago>{
    public baseName(): string {
        return "forma-pago";
    }
    public parseToEnt(data: any): FormaPago {
        return FormaPago.fromData(data);
    }
    public newEnt(): FormaPago {
        return new FormaPago();
    }


}