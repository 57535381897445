import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { MessagesService } from './../../common/services/messages-data-service.service';
import { TranslateService } from './../../common/services/translate.service';
import { AuthService } from './../../services/auth.service';


@Component({
  selector: 'recuperar-password',
  templateUrl: './recuperar-password.component.html',
  styleUrls: ['./recuperar-password.component.less']
})
export class RecuperarPasswordComponent implements OnInit, OnDestroy {

  constructor(public authService: AuthService,
    public translateService: TranslateService,
    public mService: MessagesService,
    public routing: Router) { }
  public email: string;
  public finalizado: boolean = false;
  private sub: Subscription
  public verified: string;

  ngOnInit() {
  }
  ngOnDestroy() {
    if (this.sub) this.sub.unsubscribe();
  }

  public recuperarPassword() {
    this.authService.recuperarPass(this.email).then(r => {
      this.mService.success(this.translateService.get('EL_EMAIL_A_SIDO_ENV_3'))
      this.routing.navigate(["login"]);
    });
    this.finalizado = true;
  }
  public get key() {
    return environment.recaptchaKey;
  }
  public resolved(event) {
    this.verified = event;
  }


}
