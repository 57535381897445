import { Descriptivo } from 'src/app/common/model/Descriptivo';
import { Usuario } from '../common/model/Usuario';
import { ArqueoCajaResumen } from './ArqueoCajaResumen';




export class Caja extends Descriptivo {
    constructor(id?: number, codigo?: string,
        descripcion?: string,
        // public cuentas: CajaCuentaContable[] = [],
        public usuariosAutorizados: Descriptivo[] = [],
        public ultimoCambioEstado?: ArqueoCajaResumen,
        public estaCerrada: boolean = false,
        public centroCosto?: Descriptivo,
        public locales: Descriptivo[] = []
    ) {
        super(codigo, descripcion, id)
    }

    public static fromData(data: any): Caja {
        if (!data) return null;
        const o: Caja = new Caja(
            data.id,
            data.codigo,
            data.descripcion,
            //  data.cuentas.map(c => CajaCuentaContable.fromData(c)),
            data.usuariosAutorizados.map(c => Usuario.fromData(c)),
            ArqueoCajaResumen.fromData(data.ultimoCambioEstado),
            data.estaCerrada,
            Descriptivo.fromData(data.centroCosto),
            data.locales ? data.locales.map(l => Descriptivo.fromData(l)) : []
        );

        return o;

    }

    get usuariosAutorizadosDesc() {
        return this.usuariosAutorizados.map(u => u.descripcion);
    }


    get localesDesc() {
        return this.locales?.length ? this.locales.map(l => l.descripcion).join(", ") : "Sin local";
    }
    public tieneLocal(local: Descriptivo) {
        return (local && this.locales) ? this.locales.some(l => l.id == local.id) : false;
    }


}