import { Pipe, PipeTransform } from '@angular/core';
import { Local } from 'src/app/model/Local';

@Pipe({
  name: 'noApp'
})
export class NoAppPipe implements PipeTransform {

  transform(value: Local[], args?: any): any {
    return value ? value.filter(l => !l.esApp) : [];
  }

}
