import { Parametrico } from 'src/app/common/model/Parametrico';
import { ProfilePic } from './../common/model/ProfilePic';



export class Marca extends Parametrico {
    constructor(id?: number, codigo?: string, descripcion?: string,
        habilitado: boolean = true,
        esSistema: boolean = false,
        peso: number = 99,
        public icono: ProfilePic = new ProfilePic(null, 'assets/images/default_profile.png'),


    ) {
        super(id, codigo, descripcion, habilitado, esSistema, peso)
    }
    public tipoParametro(): string {
        return "MarcaLOW";
    }
    public static fromData(data: any): Marca {
        if (!data) return null;
        const o: Marca = new Marca(
            data.id,
            data.codigo,
            data.descripcion,
            data.habilitado,
            data.esSistema,
            data.peso,

            ProfilePic.fromData(data.icono),

        );

        return o;

    }
    public get imagen() {
        return this.icono ? this.icono.picPathVersion : "assets/images/default.png";
    }
}