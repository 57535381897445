import { Pipe, PipeTransform } from '@angular/core';
import { Descriptivo } from './../common/model/Descriptivo';

@Pipe({
  name: 'notDescriptivo'
})
export class NotDescriptivoPipe implements PipeTransform {

  transform(value: Descriptivo[] = [], args?: number): any {
    return value.filter(d => !args || d.id != args);
  }

}
