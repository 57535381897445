import { Injectable } from '@angular/core';
import { ParametricoService } from 'src/app/common/services/parametricos.service';
import { EstadoDocumento } from 'src/app/model/EstadoDocumento';

@Injectable(
    {
        providedIn: "root"
    }
)
export class EstadoPedidoService extends ParametricoService<EstadoDocumento>{
    public baseName(): string {
        return "estado-pedido";
    }
    public parseToEnt(data: any): EstadoDocumento {
        return EstadoDocumento.fromData(data);
    }
    public newEnt(): EstadoDocumento {
        return new EstadoDocumento();
    }
}