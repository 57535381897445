import { Horario } from './Horario';
export class Disponibilidad {
    constructor(
        public horarios: Horario[] = [new Horario()]
    ) {

    }

    public static fromData(data: any) {
        if (!data) return new Disponibilidad();
        let o: Disponibilidad = new Disponibilidad(
            data.horarios ? data.horarios.map(h => Horario.fromData(h)) : []
        );
        return o;
    }

    public agregarHorario(item: Horario) {
        if (!this.horarios.some(ec => ec == item)) {
            this.horarios.push(item);
        }
    }
    public quitarHorario(item: Horario) {
        this.horarios = this.horarios.filter(i => i != item);
    }
    newHorario = () => {
        return new Horario();
    }
}