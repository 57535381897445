<div class="sin-cajas" *ngIf="!cajas?.length && !loading">
    <span>No se encontratron cajas abiertas.</span>
</div>

<p-dataView #dv [value]="item?.valores" emptyMessage=" " *ngIf="cajas?.length">
    <ng-template pTemplate="header">
        <div class="botones print-hidden" *ngIf="!readonly">
            <div class="boton-pago card" *ngFor="let f of formasPago | slice: 0:3" (click)="agregarCobro(f)" [ngClass]="{ disabled: readonly }">
                <profile-badge [url]="f.imagenUrl" class="small-badge desktop-badge" [disabled]="true"> </profile-badge>
                <span style="font-size: 12px">{{ f.descripcion }}</span>
            </div>

            <div class="boton-pago card" *ngIf="formasPago.length > 3" (click)="masFormasPago.show($event)" [ngClass]="{ disabled: readonly }">
                <!-- <profile-badge [url]="f.imagenUrl" class="small-badge desktop-badge" [disabled]="true"> </profile-badge> -->
                <i class="pi pi-plus"></i>
                <span style="font-size: 12px; padding-left: 1rem">{{ 'MAS_FORMAS_PAGO' | translate }}</span>
            </div>
        </div>
    </ng-template>
    <ng-template let-cobro pTemplate="listItem" let-i="rowIndex">
        <div class="col-12 mt-3" #listItem>
            <div class="grid justify-content-center">
                <div class="col-12">
                    <div class="grid">
                        <div class="col">
                            <div class="grid">
                                <div class="col-12 xl:col-4">
                                    <div class="grid" style="flex-wrap: nowrap">
                                        <div class="col-fixed text-center" style="width: 3em">
                                            <profile-badge
                                                [url]="cobro?.formaPago?.imagenUrl"
                                                class="small-badge desktop-badge"
                                                pTooltip="{{ cobro?.formaPago?.descripcion }}"
                                                [disabled]="true"
                                            >
                                            </profile-badge>
                                        </div>
                                        <div class="col">
                                            <descriptivo-material-selector
                                                [options]="cajas"
                                                [label]="'CAJA' | translate"
                                                [limpiable]="false"
                                                [(ngModel)]="cobro.caja"
                                                [permiteNuevo]="false"
                                                name="{{ 'caja' + i }}"
                                                [required]="true"
                                                [readonly]="readonly"
                                                [itemTemplate]="itemTemplate"
                                            >
                                            </descriptivo-material-selector>
                                        </div>
                                    </div>
                                </div>
                                <div class="col" [ngSwitch]="cobro.tipoCobro">
                                    <div class="grid">
                                        <div class="col mt-1">
                                            <ng-container *ngSwitchCase="'A'">
                                                <ng-container *ngTemplateOutlet="default; context: { $implicit: cobro, row: i, readonly: readonly }">
                                                </ng-container>
                                            </ng-container>
                                            <ng-container *ngSwitchCase="'E'">
                                                <ng-container *ngTemplateOutlet="efectivo; context: { $implicit: cobro, row: i, readonly: readonly }">
                                                </ng-container>
                                            </ng-container>
                                            <ng-container *ngSwitchCase="'M'">
                                                <ng-container *ngTemplateOutlet="mercadoPago; context: { $implicit: cobro, row: i }"> </ng-container>
                                            </ng-container>
                                            <ng-container *ngSwitchCase="'T'">
                                                <ng-container *ngTemplateOutlet="tarjetaCredito; context: { $implicit: cobro, row: i }"> </ng-container>
                                            </ng-container>
                                            <ng-container *ngSwitchCase="'H'">
                                                <ng-container *ngTemplateOutlet="cheque; context: { $implicit: cobro, row: i }"> </ng-container>
                                            </ng-container>
                                            <div class="grid" *ngSwitchDefault>
                                                <div class="col-12" *ngSwitchDefault>
                                                    <span>SIN COMPONENTE</span>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-12 text-right" *ngIf="cobro?.adjunto">
                                            <div class="grid justify-content-center">
                                                <div class="col col-fixed file card">
                                                    <span class="clickable" (click)="verAdjunto(cobro?.adjunto, $event)"
                                                        >{{ cobro?.adjunto?.nombreOriginal
                                                        }}<i class="pi pi-times delete" *ngIf="!readonly" (click)="quitarAdjunto(cobro, $event)"></i
                                                    ></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col col-fixed item-buttons" style="width: 3em">
                            <button
                                pButton
                                icon="pi pi-paperclip"
                                class="accion-adjunto"
                                (click)="adjuntar(cobro, $event)"
                                *ngIf="!cobro?.adjunto && !readonly"
                            ></button>
                                <button
                                    pButton
                                    class="accion-trash p-button-outlined"
                                    (click)="item.quitarCobro(cobro)"
                                    icon="pi pi-trash"
                                    *ngIf="!readonly"
                                ></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
</p-dataView>
<input type="file" (change)="handleFileInput($event.target.files)" #inputFile name="inputfile" [hidden]="true" accept=".pdf,.doc,.docx,image/*" />
<ng-template #default let-cobro let-i="row">
    <div class="grid cobro-template">
        <div class="col-4 xl:col-2" *ngIf="verMoneda">
            <parametrico-selector
                [service]="monedaService"
                #moneda="ngModel"
                [(ngModel)]="cobro.moneda"
                [limpiable]="false"
                name="moneda"
                [titulo]="'MONEDA' | translate"
                [required]="true"
                [readonly]="readonly"
            >
            </parametrico-selector>
        </div>
        <div class="{{ verMoneda ? 'p-xl-10 col-8' : 'xl:col-12 col-12' }}">
            <span-dinero-editable
                [(ngModel)]="cobro.monto"
                [readonly]="readonly"
                [label]="(cobro?.id ? 'Pagado' : 'A_PAGAR') | translate"
                name="{{ 'monto' + i }}"
                [minValue]="0"
                [maxValue]="cobro.total"
                [moneda]="cobro.moneda"
            >
            </span-dinero-editable>
        </div>
    </div>
</ng-template>
<ng-template #mercadoPago let-cobro let-i="row">
    <div class="grid cobro-template">
        <div class="col-4 xl:col-2" *ngIf="verMoneda">
            <parametrico-selector
                [service]="monedaService"
                #moneda="ngModel"
                [(ngModel)]="cobro.moneda"
                [limpiable]="false"
                name="moneda"
                [titulo]="'MONEDA' | translate"
                [required]="true"
            >
            </parametrico-selector>
        </div>
        <div class="{{ verMoneda ? 'p-xl-6 col-4' : 'p-xl-8 col-8' }}">
            <span-dinero-editable
                [(ngModel)]="cobro.monto"
                [label]="(cobro?.id ? 'Pagado' : 'A_PAGAR') | translate"
                name="{{ 'monto' + i }}"
                [minValue]="0"
                [maxValue]="cobro.total"
                [moneda]="cobro.moneda"
                [readonly]="readonly || devolucion"
            >
            </span-dinero-editable>
        </div>

        <div class="col-4 xl:col-4" *ngIf="!devolucion">
            <div class="p-inputgroup" [ngClass]="{ readonly: readonly }">
                <span class="p-float-label">
                    <input
                        pInputText
                        [(ngModel)]="cobro.idPago"
                        pTooltip="{{ 'NUMERO_DE_TRANSACCI_1' | translate }}"
                        type="number"
                        name="{{ 'numeroTransaccion' + i }}"
                        [readonly]="readonly"
                        [required]="true"
                        #numeroTransaccion="ngModel"
                    />

                    <label>{{ 'NUMERO_DE_TRANSACCI_1' | translate }}</label>
                </span>
                
                <small class="p-error block"> {{ getErrorMessage(numeroTransaccion) }}</small>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #efectivo let-cobro let-i="row">
    <div class="grid">
        <div class="col-4 xl:col-2" *ngIf="verMoneda">
            <parametrico-selector
                [service]="monedaService"
                #moneda="ngModel"
                [(ngModel)]="cobro.moneda"
                [limpiable]="false"
                name="moneda"
                [titulo]="'MONEDA' | translate"
                [readonly]="readonly"
            >
            </parametrico-selector>
        </div>
        <div class="{{ verMoneda ? 'p-xl-10 col-8 nopad' : 'xl:col-12 col-12 nopad' }}">
            <div class="grid grid-nogutter cobro-template">
                <div class="col">
                    <span-dinero-editable
                        [(ngModel)]="cobro.monto"
                        [label]="(cobro?.id ? 'Pagado' : 'A_PAGAR') | translate"
                        name="{{ 'monto' + i }}"
                        [minValue]="0"
                        [maxValue]="cobro.total"
                        [moneda]="cobro.moneda"
                        [required]="true"
                        [readonly]="readonly || devolucion"
                    >
                    </span-dinero-editable>
                </div>
                <div class="col-3 xs-6" *ngIf="!cobro?.id && !readonly">
                    <span-dinero-editable
                        [(ngModel)]="cobro.pagaCon"
                        [label]="'Paga con ' | translate"
                        name="{{ 'pagacon' + i }}"
                        [minValue]="cobro.total"
                        [moneda]="cobro.moneda"
                        [readonly]="readonly || cobro.monto <= 0"
                    >
                    </span-dinero-editable>
                </div>
                <div class="col-3 xs-6" *ngIf="!cobro?.id && !readonly">
                    <span-dinero-editable [importeMuestra]="cobro.pagaCon - cobro.monto" [readonly]="true" [label]="'Vuelto' | translate">
                    </span-dinero-editable>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #tarjetaCredito let-cobro let-i="row">
    <div class="grid cobro-template">
        <div class="col-4 xl:col-2" *ngIf="verMoneda">
            <parametrico-selector
                [service]="monedaService"
                #moneda="ngModel"
                [(ngModel)]="cobro.moneda"
                [readonly]="readonly"
                [limpiable]="false"
                name="moneda"
                [titulo]="'MONEDA' | translate"
                [required]="true"
            >
            </parametrico-selector>
        </div>

        <div class="col-6 p-xl-6">
            <parametrico-selector
                [service]="tipoTarjetaCreditoService"
                #tarjeta="ngModel"
                [readonly]="readonly"
                [(ngModel)]="cobro.tipoTarjeta"
                [limpiable]="false"
                name="tarjeta"
                [titulo]="'TARJETA' | translate"
                [required]="true"
            >
            </parametrico-selector>
        </div>

        <div class="col-3 xl:col-3">
            <parametrico-selector
                [service]="tipoCuotasTarjetaService"
                #cuotas="ngModel"
                [readonly]="readonly"
                [(ngModel)]="cobro.cantidadCuotas"
                [limpiable]="false"
                name="cuotas"
                [titulo]="'CUOTAS' | translate"
                [required]="true"
            >
            </parametrico-selector>
        </div>

        <div class="col-3 xl:col-3 p-mb-2">
            <div class="p-inputgroup" [ngClass]="{ readonly: readonly }">
                <span class="p-float-label">
                    <input
                        pInputText
                        [(ngModel)]="cobro.numeroTarjeta"
                        pTooltip="{{ 'NUMERO_TARJETA' | translate }}"
                        type="text"
                        maxLength="4"
                        minLength="4"
                        [readonly]="readonly"
                        required="true"
                        (keypress)="numberOnly($event)"
                        #numeroTarjeta="ngModel"
                    />

                    <label>{{ 'NUMERO_TARJETA' | translate }}</label>
                </span>
                <small class="p-error block">{{ getErrorMessage(numeroTarjeta) }}</small>
            </div>
        </div>

        <div class="col-6 p-xl-6 p-mb-2">
            <div class="p-inputgroup" [ngClass]="{ readonly: readonly }">
                <span class="p-float-label">
                    <input
                        pInputText
                        [(ngModel)]="cobro.idPago"
                        pTooltip="{{ 'NUMERO_DE_TRANSACCI_1' | translate }}"
                        type="number"
                        name="{{ 'numeroTransaccion' + i }}"
                        [readonly]="readonly"
                        required="true"
                        #numeroTransaccion="ngModel"
                    />

                    <label>{{ 'NUMERO_DE_TRANSACCI_1' | translate }}</label>
                </span>
                <small class="p-error block">{{ getErrorMessage(numeroTransaccion) }}</small>
            </div>
        </div>

        <div class="{{ verMoneda ? 'p-xl-6 col-6' : 'p-xl-6 col-6' }}">
            <span-dinero-editable
                [(ngModel)]="cobro.monto"
                [readonly]="readonly"
                [label]="(cobro?.id ? 'Pagado' : 'A_PAGAR') | translate"
                name="{{ 'monto' + i }}"
                [minValue]="0"
                [maxValue]="cobro.total"
                [moneda]="cobro.moneda"
            >
            </span-dinero-editable>
        </div>
    </div>
</ng-template>

<ng-template #cheque let-cobro let-i="row">
    <div class="grid cobro-template">
        <div class="col-3">
            <div class="p-inputgroup" [ngClass]="{ readonly: readonly || !editable }">
                <span class="p-float-label">
                    <p-calendar
                        dateFormat="dd/mm/yy"
                        [(ngModel)]="cobro.fechaDeCobro"
                        #fechaDeCobro="ngModel"
                        name="fechaDeCobro"
                        [disabled]="readonly"
                        required="true"
                    >
                    </p-calendar>
                    <label> {{ 'FECHA_DE_COBRO' | translate }}</label>
                </span>
                <small class="p-error block">{{ getErrorMessage(fechaDeCobro) }}</small>
            </div>
        </div>

        <div class="col-3">
            <div class="p-inputgroup" [ngClass]="{ readonly: readonly }">
                <span class="p-float-label">
                    <input
                        pInputText
                        [(ngModel)]="cobro.banco"
                        pTooltip="{{ 'BANCO' | translate }}"
                        type="text"
                        [readonly]="readonly"
                        required="true"
                        name="cobroBanco"
                        #cobroBanco="ngModel"
                    />

                    <label>{{ 'BANCO' | translate }}</label>
                </span>
                <small class="p-error block">{{ getErrorMessage(cobroBanco) }}</small>
            </div>
        </div>

        <div class="col-3">
            <div class="p-inputgroup" [ngClass]="{ readonly: readonly }">
                <span class="p-float-label">
                    <input
                        pInputText
                        [(ngModel)]="cobro.numeroCheque"
                        pTooltip="{{ 'NUMERO_CHEQUE' | translate }}"
                        type="text"
                        [readonly]="readonly"
                        required="true"
                        name="cobroNumeroCheque"
                        (keypress)="numberOnly($event)"
                        #numeroCheque="ngModel"
                    />

                    <label>{{ 'NUMERO_CHEQUE' | translate }}</label>
                </span>
                <small class="p-error block">{{ getErrorMessage(numeroCheque) }} </small>
            </div>
        </div>

        <div class="{{ verMoneda ? 'xl:col-3 col-3' : 'xl:col-3 col-3' }}">
            <span-dinero-editable
                [(ngModel)]="cobro.monto"
                [label]="(cobro?.id ? 'Pagado' : 'A_PAGAR') | translate"
                name="{{ 'monto' + i }}"
                [minValue]="0"
                [maxValue]="cobro.total"
                [moneda]="cobro.moneda"
                [readonly]="readonly"
            >
            </span-dinero-editable>
        </div>
    </div>
</ng-template>
<p-overlayPanel #masFormasPago [showCloseIcon]="true" appendTo="body">
    <ng-template pTemplate>
        <p>Formas de Pago</p>
        <div class="botones print-hidden" *ngIf="!readonly">
            <div
                class="boton-pago card"
                *ngFor="let f of formasPago | slice: 3"
                (click)="agregarCobro(f); masFormasPago.hide()"
                [ngClass]="{ disabled: readonly }"
            >
                <profile-badge [url]="f.imagenUrl" class="small-badge desktop-badge" [disabled]="true"> </profile-badge>
                <span style="font-size: 12px">{{ f.descripcion }}</span>
            </div>
        </div>
    </ng-template>
</p-overlayPanel>
