import { PuntoDeVenta } from 'src/app/model/PuntoDeVenta';
import * as moment from 'moment'
import { Descriptivo } from 'src/app/common/model/Descriptivo'
import { TipoComprobante } from 'src/app/model/TipoComprobante'
import { ClienteResumen } from './Cliente'
import { EstadoDocumento } from './EstadoDocumento'
import { ItemComprobante } from './ItemComprobante'
import { Moneda } from './Moneda'

export class Comprobante {
    public clienteStr: string
    constructor(
        public id?: number,
        public numeroDocumento?: string,
        public descripcion?: string,
        public fecha: Date = new Date(),
        public fechaVencimiento: Date = moment().add(10, 'days').startOf('day').toDate(),
        public tipoComprobante?: TipoComprobante,
        public cliente?: ClienteResumen,
        public items: ItemComprobante[] = [],
        public iibbOtros?: number,
        public saldo?: number,
        public importe?: number,
        public estado?: EstadoDocumento,
        public responsable?: Descriptivo,
        public reserva?: Descriptivo,
        public bonificacion: number = 0,
        public redondeo: number = 0,
        public observaciones: string = '',
        public moneda?: Moneda,
        public comprobanteAsociado?: Descriptivo,
        public monedaOrigen?: Moneda,
        public cotizacion: number = 1,
        public local?: Descriptivo,
        public pedido?: Descriptivo,
        public numeroComprobante?: string,
        public cae?: string,
        public vencimientoCae?: Date,
        public puntoDeVenta?: PuntoDeVenta
    ) {
        this.clienteStr = this.cliente ? this.cliente.descripcion : ''
    }
    public static fromData(data: any): Comprobante {
        if (!data) return null
        const o: Comprobante = new Comprobante(
            data.id,
            data.numeroDocumento,
            data.descripcion,
            data.fecha ? new Date(data.fecha) : null,
            data.fechaVencimiento ? new Date(data.fechaVencimiento) : null,
            TipoComprobante.fromData(data.tipoComprobante),
            ClienteResumen.fromData(data.cliente),
            data.items ? data.items.map((c) => ItemComprobante.fromData(c)) : [],
            data.iibbOtros,
            data.saldo,
            data.importe,
            EstadoDocumento.fromData(data.estado),
            Descriptivo.fromData(data.responsable),
            Descriptivo.fromData(data.reserva),
            data.bonificacion,
            data.redondeo,
            data.observaciones,
            Moneda.fromData(data.moneda),
            Descriptivo.fromData(data.comprobanteAsociado),
            Moneda.fromData(data.monedaOrigen),
            data.cotizacion,
            Descriptivo.fromData(data.local),
            Descriptivo.fromData(data.pedido),
            data.numeroComprobante,
            data.cae,
            data.vencimientoCae,
            PuntoDeVenta.fromData(data.puntoDeVenta)
        )

        return o
    }
    public get getNumeroDocumento() {
        return this.numeroDocumento ? this.numeroDocumento : this.numeroComprobante
    }
    public get subtotalNeto() {
        return this.items.reduce((a, b) => (a +=(b.precioUnitario- b.impuestos) * b.cantidad), 0)
    }
    public get subtotalBruto() {
        return this.items.reduce((a, b) => (a += b.precioUnitarioBruto * b.cantidad), 0)
    }
    public get descuentos() {
        return this.items.reduce((a, b) => (a += b.valorDescuento * b.cantidad), 0) + this.bonificacion
    }
    public get impuestos() {
        return this.items.reduce((a, b) => (a += b.impuestos * b.cantidad), 0)
    }
    public get total() {
    
        return this.importe || this.subtotalBruto  + this.redondeo + this.impuestos - this.bonificacion
    }

    public quitarItemComprobante(item) {
        this.items = this.items.filter((i) => i != item)
    }

    public agregarItem(item) {
        if (this.items.indexOf(item) < 0) {
            this.items.push(item)
        }
    }
    public newItemComprobante() {
        return new ItemComprobante()
    }

    public static crearResumen(comp: Comprobante): ComprobanteResumen {
        return new ComprobanteResumen(
            comp.id,
            comp.numeroDocumento,
            comp.descripcion,
            comp.fecha,
            comp.fechaVencimiento,
            comp.tipoComprobante,
            comp.cliente,
            null,
            comp.saldo,
            comp.importe,
            comp.estado,
            comp.pedido,
            comp.moneda,
            comp.local,
            comp.impuestos,
            comp.iibbOtros
        )
    }
}
export class ComprobanteResumen {
    public clienteStr: string
    public cuitCliente: string
    public importeBruto : number;
    constructor(
        public id?: number,
        public numeroDocumento?: string,
        public descripcion?: string,
        public fecha?: Date,
        public fechaVencimiento?: Date,
        public tipoComprobante?: TipoComprobante,
        public cliente?: ClienteResumen,
        public centroCosto?: Descriptivo,
        public saldo?: number,
        public importe?: number,
        public estado?: EstadoDocumento,
        public pedido?: Descriptivo,
        public moneda?: Moneda,
        public local?: Descriptivo,
        public impuestos: number = 0,
        public otrosImpuestos: number = 0
    ) {
        this.clienteStr = this.cliente ? this.cliente.descripcion : ''
        this.cuitCliente = this.cliente ? this.cliente.numeroDocumento : ''
        this.importeBruto = this.importe - this.impuestos;
    }
    public get fechaStr() {
        return moment(this.fecha).format('DD/MM/YYYY')
    }
    public get tipoComprobanteCodigo() {
        return this.tipoComprobante?.codigo || ''
    }
    public get nombreCliente() {
        return this.cliente ? this.cliente.razonSocial : ''
    }  
    public static fromData(data: any): ComprobanteResumen {
        if (!data) return null
        const o: ComprobanteResumen = new ComprobanteResumen(
            data.id,
            data.numeroDocumento,
            data.descripcion,
            data.fecha ? new Date(data.fecha) : null,
            data.fechaVencimiento ? new Date(data.fechaVencimiento) : null,
            TipoComprobante.fromData(data.tipoComprobante),
            ClienteResumen.fromData(data.cliente),
            Descriptivo.fromData(data.centroCosto),
            data.saldo,
            data.importe,
            EstadoDocumento.fromData(data.estado),
            Descriptivo.fromData(data.pedido),
            Moneda.fromData(data.moneda),
            Descriptivo.fromData(data.local),
            data.impuestos,
            data.otrosImpuestos
        )

        return o
    }
    public get numeroDocSafe() {
        return this.numeroDocumento ? this.tipoComprobante.codigo + ' ' + this.numeroDocumento : this.id
    }

}
