import { Injectable } from '@angular/core';
import { ServicioAbstract } from 'src/app/common/services/service.service';
import { TraspasoDeCaja } from 'src/app/model/TraspasoDeCaja';
@Injectable({
    providedIn: 'root',
})
export class TraspasoDeCajaService extends ServicioAbstract<TraspasoDeCaja>{
    public baseName(): string {
        return "traspaso-de-caja";
    }
    public parseToEnt(data: any): TraspasoDeCaja {
        return TraspasoDeCaja.fromData(data);
    }
    public newEnt(): TraspasoDeCaja {
        return new TraspasoDeCaja();
    }


}