import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { FiltroMovimiento } from 'src/app/common/model/FiltroMovimiento';
import { Caja } from 'src/app/model/Caja';
import { Descriptivo } from '../common/model/Descriptivo';
import { BufferedDescriptivoService } from './BufferedDescriptivoService';
import { SaldosTotales } from './movimiento-caja.service';
@Injectable({
    providedIn: 'root',
})
export class CajaService extends BufferedDescriptivoService<Caja>{
    /* constructor(ns: NovedadService, ms?: MessagesService) {
         super(ns, new BehaviorSubject<Caja[]>([]), ms)
     }*/
    public baseName(): string {
        return "caja";
    }
    public parseToEnt(data: any): Caja {
        return Caja.fromData(data);
    }
    public newEnt(): Caja {
        return new Caja();
    }
    public getSaldo(caja: Caja): Promise<number> {
        var filtro = new FiltroMovimiento("dummy");
        filtro.guardable = false;
        filtro.fechaDesde = moment(new Date()).add(1, 'day').toDate();
        filtro.formaPago = new Descriptivo("EF")
        filtro.caja = caja;

        return this.http.post(this.getApiURL() + "movimiento-caja/getSaldoHasta", filtro.json).toPromise().then((s: SaldosTotales) => { return s.saldo; });
    }

}