import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UsuarioService } from 'src/app/common/services/usuario.service';
import { environment } from './../../../environments/environment';
import { ErrorHandler } from './../../common/utils/ErrorsHandler';
import { Registro } from './Registro';

@Component({
  selector: 'app-registrarse',
  templateUrl: './registrarse.component.html',
  styleUrls: ['./registrarse.component.less']
})
export class RegistrarseComponent implements OnInit {

  constructor(private userService: UsuarioService, private router: Router) { }
  public captcha;
  public registro: Registro = new Registro()
  public verified: boolean = false;
  public esperarMail: boolean = false;
  public error: string;
  public recapcha = new FormControl("", Validators.required);
  ngOnInit() {
  }
  public get key() {
    return environment.recaptchaKey;
  }
  public resolved(event) {
    this.verified = event;
  }
  getErrorMessage(control: AbstractControl) {
    if (control?.errors?.length > 0) {
      return ErrorHandler.getErrorMessage(control);
    }

  }
  public registrarse() {
    if (this.verified) {
      this.userService.registrar(this.registro).then(r => {
        this.esperarMail = true;
        this.error = "";

        setTimeout(() => {
          this.router.navigate(['login'])
        }, 5000)
      })
    }
  }
}
