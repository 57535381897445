import * as moment from 'moment'
import { Descriptivo } from './../common/model/Descriptivo'
import { Filtro } from './../common/model/Filtro'
export class FiltroProducto extends Filtro {
    constructor(name: string, page?: number, size?: number, sortField: string = 'peso', order: number = 1, guardable: boolean = true) {
        super(
            name,
            {
                categorias: 'Categorías',
                tipo: 'Tipo de producto',
                archivados: 'Ver archivados',
                cliente: 'Cliente',
                fechaDesde: 'Fecha desde',
                fechaHasta: 'Fecha hasta',
                ordenadoPor: 'Ordenado por',
                soloRapidos: 'Ver solo rápidos',
                locales: 'Locales',
                centros: 'Centro de costo',
            },
            page,
            size,
            sortField,
            order,
            guardable
        )
    }

    private _verArchivados: boolean
    private _categorias: Descriptivo[] = []
    private _fechaDesde: Date
    private _fechaHasta: Date
    private _cliente: Descriptivo
    private _centros: Descriptivo[] = []
    private _tiposProducto: Descriptivo[] = []
    private _soloVenta: boolean = false
    public get soloVenta(): boolean {
        return this._soloVenta
    }
    public set soloVenta(v: boolean) {
        if (v != this._soloVenta) {
            this._soloVenta = v
            this.update()
        }
    }
    public get tiposProducto(): Descriptivo[] {
        return this._tiposProducto
    }
    public set tiposProducto(v: Descriptivo[]) {
        this._tiposProducto = v
        this.update()
    }
    public get centros(): Descriptivo[] {
        return this._centros
    }
    public set centros(v: Descriptivo[]) {
        if (v != this._centros) {
            this._centros = v
            this.update()
        }
    }
    private _locales: Descriptivo[]
    public get locales(): Descriptivo[] {
        return this._locales
    }
    public set locales(v: Descriptivo[]) {
        if (v != this._locales) {
            this._locales = v
            this.update()
        }
    }
    private _soloRapidos: boolean
    public get soloRapidos(): boolean {
        return this._soloRapidos
    }
    public set soloRapidos(v: boolean) {
        if (v != this._soloRapidos) {
            this._soloRapidos = v
            this.update()
        }
    }
    private _excluirRapidos: boolean
    public get excluirRapidos(): boolean {
        return this._excluirRapidos
    }
    public set excluirRapidos(v: boolean) {
        this._excluirRapidos = v
        this.update()
    }

    private _ordenadoPor: string = 'cantidad'
    public get ordenadoPor(): string {
        return this._ordenadoPor
    }

    public set ordenadoPor(v: string) {
        this._ordenadoPor = v
        this.sortField = v
        this.update()
    }

    private _cantidad: number
    public get cantidad(): number {
        return this.size
    }
    public set cantidad(v: number) {
        this.size = v
        this.update()
    }

    public get categorias(): Descriptivo[] {
        return this._categorias
    }
    public set categorias(v: Descriptivo[]) {
        this._categorias = v
        this.update()
    }

    public get cliente(): Descriptivo {
        return this._cliente
    }
    public set cliente(v: Descriptivo) {
        this._cliente = v
        this.update()
    }

    public get tipo(): Descriptivo {
        return this._tiposProducto ? this._tiposProducto[0] : null
    }
    public set tipo(v: Descriptivo) {
        if (!this._tiposProducto) this._tiposProducto = []
        if (this._tiposProducto[0] != v) {
            this._tiposProducto = [v]
            this.update()
        }
    }

    public get fechaDesde(): Date {
        return this._fechaDesde
    }
    public set fechaDesde(v: Date) {
        this._fechaDesde = v ? moment(v).startOf('day').toDate() : null
        this.update()
    }

    public get fechaHasta(): Date {
        return this._fechaHasta
    }
    public set fechaHasta(v: Date) {
        this._fechaHasta = v ? moment(v).endOf('day').toDate() : null
        this.update()
    }
    public get verArchivados(): boolean {
        return this._verArchivados
    }
    public set verArchivados(v: boolean) {
        if (v != this._verArchivados) {
            this._verArchivados = v
            this.update()
        }
    }

    public clean() {
        super.clean(false)
        let cleanVal = {
            categoria: [],
            verArchivados: false,
            tiposProducto: [],
            cliente: null,
            fechaDesde: null,
            fechaHasta: null,
            ordenadoPro: 'cantidad',
            solorapidos: false,
            centros: [],
            locales: [],
            soloVenta: true,
            size: 20,
        }
        this.patchValue(cleanVal)
    }

    public patchValue(v: any) {
        super.patchValue(v, false)
        //this._searchStr = v.searchStr;
        this._verArchivados = v.verArchivados
        this._tiposProducto = v.tiposProducto ? v.tiposProducto.map((d) => Descriptivo.fromData(d)) : []
        this._categorias = v.categorias ? v.categorias.map((c) => Descriptivo.fromData(c)) : []
        this._cliente = v.cliente ? Descriptivo.fromData(v.cliente) : null
        this._fechaDesde = v.fechaDesde ? new Date(v.fechaDesde) : null
        this._fechaHasta = v.fechaHasta ? new Date(v.fechaHasta) : null
        this._ordenadoPor = v.ordenadoPro ? v.ordenadoPor : 'cantidad'
        this._soloRapidos = v.soloRapidos
        this._centros = v.centros ? v.centros.map((c) => Descriptivo.fromData(c)) : []
        this._locales = v.locales ? v.locales.map((c) => Descriptivo.fromData(c)) : []
        this._soloVenta = v.soloVenta
        this.update()
    }

    public apply(data: any[]): any[] {
        const searchLike = this.searchStr ? this.searchStr.toUpperCase().split(' ') : null
        return data.filter(
            (value) =>
                (!searchLike || !value['descripcion'] || value['codigo'] === this.searchStr 
                ||  searchLike.every((v) => value['descripcion'].toUpperCase().includes(v))) &&
                (!this.soloRapidos || value['esRapido']) &&
                (this.verArchivados || value['habilitado']) &&
                (!this.soloVenta || value['esVenta']) &&
                this.aplicaCategorias(value) &&
                    this.aplicaCentros(value) &&
                    this.aplicaLocales(value) &&
                (!value['tipoProducto'] || !this.tiposProducto?.length || this.tiposProducto.some((d) => d.codigo === value['tipoProducto'].codigo))
        )
    }
    
    private aplicaCentros(value):boolean {
        return (!this.centros?.length ||
            !value['centroCosto'] ||
            (this.centros.some((f) => value['centroCosto']?.codigo === f?.codigo)))
    }

    private aplicaCategorias(value): boolean {

       return (!this.categorias?.length ||
            !value['categorias'] ||
            (value['categorias'].length && value['categorias'].some((cat) => this.categorias.some((f) => cat.codigo === f.codigo))))

    }

    private aplicaLocales(value): boolean {
        return (!this.locales?.length ||
            !value['locales'] ||
            (value['locales'].length && value['locales'].some((loc) => this.locales.some((f) => loc.codigo === f.codigo))))

    }
}
