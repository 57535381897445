import { Injectable } from '@angular/core';
import { ParametricoService } from 'src/app/common/services/parametricos.service';
import { Moneda } from 'src/app/model/Moneda';
import { LoadingService } from './../common/services/loading-data-service.service';
import { Cotizacion } from './../model/Cotizacion';
@Injectable({
    providedIn: 'root',
})
export class MonedaService extends ParametricoService<Moneda>{
    static cotizaciones: Map<String, Cotizacion[]> = new Map();
    getCotizacion(monedaDesde: Moneda, monedaHasta: Moneda) {
        if (!monedaDesde || !monedaHasta) return 1;
        var c = MonedaService.cotizaciones.get(monedaDesde.codigo);
        return c ? c.filter(cc => cc.desde == monedaHasta.codigo)[0]?.valor : 1;
    }
    public baseName(): string {
        return "moneda";
    }
    public parseToEnt(data: any): Moneda {
        return Moneda.fromData(data);
    }
    public newEnt(): Moneda {
        return new Moneda();
    }
    protected loadData(customLoading?: LoadingService): Promise<Moneda[]> {
        var p = super.loadData(customLoading);
        MonedaService.cotizaciones = new Map();
        MonedaService.cotizaciones.set("P", [new Cotizacion('D', 0.014),
        new Cotizacion('E', 0.011)
        ]);
        MonedaService.cotizaciones.set("E", [new Cotizacion('D', 1.18),
        new Cotizacion('P', 86.96)
        ]);
        MonedaService.cotizaciones.set("D", [new Cotizacion('E', 0.85),
        new Cotizacion('P', 73.72)
        ]);
        return p;

    }

}