export class StringUtils {

    public static capitalize = (cadena: string) => {
        return cadena.toLowerCase().replace(/\b\w/g, function (l) { return l.toUpperCase() });
    }
    public static middlescoreToTitleCase(val: string) {
        var words = val.split("-");
        words.forEach((w, i) => {
            words[i] = w.charAt(0).toUpperCase() + w.slice(1)
        });
        return words.join(" ");
    }
    public static isMailValid = (email: string) => {
        return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email);
    }

    public static toTitleCase(val: string) {
        var words = val.replace(/([A-Z])/g, " $1");
        return words.charAt(0).toUpperCase() + words.slice(1);
    }

    public static getSinTildes(original: string): string {
        let str: string = original ? original.toUpperCase() : "";
        str = str.replace(new RegExp(/[àáâãäå]/g), "a")
            .replace(new RegExp(/[èéêë]/g), "e")
            .replace(new RegExp(/[ìíîï]/g), "i")
            .replace(new RegExp(/[òóôõö]/g), "o")
            .replace(new RegExp(/[ùúûü]/g), "u");
        return str;
    }

    public static contiene(a: string = "", b: string = "") {
        return StringUtils.getSinTildes(a).includes(StringUtils.getSinTildes(b));
    }


}