import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { BehaviorSubject } from 'rxjs';
import { GestorComandasAG } from 'src/app/authguards/GestorComandasAG';
import { GestorReservasAG } from 'src/app/authguards/GestorReservasAG';
import { GestorStockAG } from 'src/app/authguards/GestorStockAG';
import { PedidosAG } from 'src/app/authguards/PedidosAG';
import { PedidosPagerAG } from 'src/app/authguards/PedidosPagerAG';
import { VentasAG } from 'src/app/authguards/VentasAG';
import { Usuario } from 'src/app/common/model/Usuario';
import { BaseService } from 'src/app/common/services/base.service';
import { Novedad } from 'src/app/model/Novedad';
import { AdminAuthGuard } from './../../authguards/AdminAuthGuard';
import { AdministracionAuthGuard } from './../../authguards/AdministracionAuthGuard';
import { AperturaCajaAG } from './../../authguards/AperturaCajaAG';
import { CajaIngresoEgresoAG } from './../../authguards/CajaIngresoEgresoAG';
import { CobranzasAG } from './../../authguards/CobranzasAG';
import { ComprobantesAG } from './../../authguards/ComprobantesAG';
import { FacturasAG } from './../../authguards/FacturasAG';
import { GestionMenuAG } from './../../authguards/GestionMenuAG';
import { GestorClientesAG } from './../../authguards/GestorClientesAG';
import { GestorPreciosAG } from './../../authguards/GestorPreciosAG';
import { GestorProductosAG } from './../../authguards/GestorProductoAG';
import { GestorProveedorAG } from './../../authguards/GestorProveedorAG';
import { InsumosAG } from './../../authguards/InsumosAG';
import { OrdenPagoAG } from './../../authguards/OrdenPagoAG';
import { PromocionesAG } from './../../authguards/PromocionesAG';
import { ReporteAG } from './../../authguards/ReporteAG';
import { ResumenCajaAG } from './../../authguards/ResumenCajaAG';
import { TraspasoCajaAG } from './../../authguards/TraspasoCajaAG';
import { AuthService } from './../../services/auth.service';
import { IActualizable, NovedadService } from './../../services/novedades.service';
import { TranslateService } from './translate.service';
@Injectable({
    providedIn: 'root',
})
export class MenuConfigService extends BaseService implements IActualizable {
    public baseName(): string {
        return 'menu-config'
    }

    constructor(
        private novedadService: NovedadService,
        private authService: AuthService,
        private adminAuthGuard: AdminAuthGuard,
        private administracionAG: AdministracionAuthGuard,
        private router: Router,
        private ventasAG: VentasAG,
        private pedidosAG: PedidosAG,
        private clientesAG: GestorClientesAG,
        private cobranzasAG: CobranzasAG,
        private comprobantesAG: ComprobantesAG,
        private pedidosPagerAG: PedidosPagerAG,
        private gestorComandas: GestorComandasAG,
        private gestorProductosAG: GestorProductosAG,
        private gestorPromocionesAG: PromocionesAG,
        private gestionMenuAG: GestionMenuAG,
        private gestorProveedorAG: GestorProveedorAG,
        private gestorFacturasAG: FacturasAG,
        private gReservas: GestorReservasAG,
        private gestorPrecios: GestorPreciosAG,
        private gestorStockAG: GestorStockAG,
        private gestorOrdenPAgo: OrdenPagoAG,
        private gestorInsumosAG: InsumosAG,
        private reportesAG: ReporteAG,
        private resCajaAG: ResumenCajaAG,
        private cajaIngresoEgresoAG: CajaIngresoEgresoAG,
        private aperturaCajaAG: AperturaCajaAG,
        private traspasoCajaAG: TraspasoCajaAG,
        private translateService: TranslateService
    ) {
        super()
        this.init()
    }
    next(n: Novedad) {
        this.updateBS(this.generarMenu(this.authService.usuario))
    }
    destroy() {
        this.menuBS.next([])
    }
    public menuBS: BehaviorSubject<MenuItem[]> = new BehaviorSubject([])
    public rutasBS: BehaviorSubject<MenuItem[]> = new BehaviorSubject([])
    private generarMenu(usuario: Usuario) {
        var items: MenuItem[] = [
            {
                label: this.translateService.get('CERRAR_SESIÓN'),
                visible: true,
                command: () => {
                    this.authService.logout()
                },
                icon: 'fa fa-sign-out ',
            },
        ]
        let $this = this
        items = [
            {
                label: this.translateService.get('CERRAR_SESIÓN'),
                visible: true,
                command: () => {
                    this.authService.logout()
                },
                icon: 'fa fa-sign-out ',
            },
        ]
        const ventas = [
            {
                label: this.translateService.get('CLIENTE_TITLE'),
                icon: 'fa fa-list',
                visible: this.clientesAG.esVisible(usuario),
                routerLink: ['cliente'],
            },
            {
                label: this.translateService.get('PRODUCTO_TITLE'),
                icon: 'fa fa-gift',
                visible: this.gestorProductosAG.esVisible(usuario),
                routerLink: ['producto'],
            },
            {
                label: this.translateService.get('LISTAS_PRECIOS'),
                icon: 'fa fa-list',
                visible: this.gestorPrecios.esVisible(usuario),
                routerLink: ['lista-precios'],
            },
            {
                label: this.translateService.get('PEDIDOS'),
                icon: 'fa fa-gift',
                visible: this.pedidosAG.esVisible(usuario),
                routerLink: ['pedido'],
            },
            {
                label: this.translateService.get('Pedidos Pager'),
                icon: 'fa fa-bell-o',
                visible: this.pedidosPagerAG.esVisible(usuario),
                routerLink: ['pedido-token'],
            },
            {
                label: this.translateService.get('Comandas'),
                icon: 'fa fa-cutlery',
                visible: this.gestorComandas.esVisible(usuario),
                routerLink: ['comanda'],
            },
            {
                label: this.translateService.get('COBRANZAS'),
                icon: 'fa fa-dollar',
                visible: this.cobranzasAG.esVisible(usuario),
                routerLink: ['cobranza'],
            },

            {
                label: this.translateService.get('COMPROBANTES'),
                icon: 'fa fa-gift',
                visible: this.comprobantesAG.esVisible(usuario),
                routerLink: ['comprobante'],
            },

            {
                label: this.translateService.get('STOCK_TITLE'),
                icon: 'pi pi-table',
                visible: this.gestorStockAG.esVisible(usuario),
                routerLink: ['stock'],
            },
            {
                label: this.translateService.get('GESTION_MENU'),
                icon: 'fa fa-list',
                visible: this.gestionMenuAG.esVisible(usuario),
                routerLink: ['menu'],
            },
            {
                label: this.translateService.get('PROMOCION_TITULO'),
                icon: 'fa fa-percent',
                visible: this.gestorPromocionesAG.esVisible(usuario),
                routerLink: ['promocion'],
            },
        ]
        if (usuario && this.translateService.data) {
            items = [
                {
                    label: this.translateService.get('Home'),
                    icon: 'fa fa-home',
                    routerLink: ['home'],
                },
                {
                    label: this.translateService.get('COMPRAS'),
                    icon: 'fa fa-money',
                    visible: this.gestorInsumosAG.esVisible(usuario) || this.gestorProveedorAG.esVisible(usuario) || this.gestorFacturasAG.esVisible(usuario) || this.gestorOrdenPAgo.esVisible(usuario) ,
                    items: [
                        {
                            label: this.translateService.get('PROVEEDOR_TITLE'),
                            icon: 'fa fa-users',
                            visible: this.gestorProveedorAG.esVisible(usuario),
                            routerLink: ['proveedor'],
                        },
                        {
                            label: this.translateService.get('FACTURA_TITLE'),
                            visible: this.gestorFacturasAG.esVisible(usuario),
                            icon: 'fa fa-money',
                            routerLink: ['factura'],
                        },
                        {
                            label: this.translateService.get('ORDEN-PAGO_TITLE'),
                            visible: this.gestorOrdenPAgo.esVisible(usuario),
                            icon: 'fa fa-money',
                            routerLink: ['orden-pago'],
                        },
                        {
                            label: this.translateService.get('INSUMO_TITLE'),
                            visible: this.gestorInsumosAG.esVisible(usuario),
                            icon: 'fa fa-archive',
                            routerLink: ['insumo'],
                        },
                    ],
                },
                {
                    label: this.translateService.get('VENTAS'),
                    icon: 'fa fa-tag',
                    visible: ventas.filter((i) => i.visible)?.length > 0,
                    items: ventas,
                },
                {
                    label: this.translateService.get('Reservas'),
                    icon: 'fa fa-calendar-o',
                    visible: this.gReservas.esVisible(usuario),
                    items: [
                        {
                            label: this.translateService.get('Reservas'),
                            icon: 'fa fa-calendar-o',
                            visible: this.gReservas.esVisible(usuario),
                            routerLink: ['reserva'],
                        },
                    ],
                },
                {
                    label: this.translateService.get('FINANCIERO_TITLE'),
                    icon: 'fa fa-money',
                    visible: this.cajaIngresoEgresoAG.esVisible(usuario) || this.resCajaAG.esVisible(usuario) || this.traspasoCajaAG.esVisible(usuario) || this.aperturaCajaAG.esVisible(usuario),
                    items: [
                        {
                            label: this.translateService.get('Resumen de caja'),
                            icon: 'fa fa-money',
                            visible: this.resCajaAG.esVisible(usuario),
                            routerLink: ['caja/movimiento-caja'],
                        },
                        {
                            label: this.translateService.get('Totales de caja'),
                            icon: 'fa fa-usd',
                            visible: this.resCajaAG.esVisible(usuario),
                            routerLink: ['caja/totales-caja'],
                        },
                        {
                            label: this.translateService.get('Traspasos de caja'),
                            icon: 'fa fa-exchange',
                            visible: this.traspasoCajaAG.esVisible(usuario),
                            routerLink: ['traspaso-de-caja'],
                        },

                        {
                            label: this.translateService.get('Cierre / Apertura de caja'),
                            visible: this.aperturaCajaAG.esVisible(usuario),
                            icon: 'fa fa-briefcase ',
                            routerLink: ['cambio-estado-caja'],
                        },
                        {
                            label: this.translateService.get('Retiros / Ingresos'),
                            icon: 'fa fa-retweet',
                            visible: this.cajaIngresoEgresoAG.esVisible(usuario),
                            routerLink: ['cambio-saldo-caja'],
                        },
                    ],
                },
                {
                    label: this.translateService.get('Reportes'),
                    icon: 'fa fa-bar-chart',
                    visible: this.reportesAG.esVisible(usuario),
                    items: [
                        {
                            label: this.translateService.get('Ranking de Ventas'),
                            visible: true,
                            routerLink: ['reportes/ranking-productos'],
                        },
                        {
                            label: this.translateService.get('Ingresos y egresos'),
                            visible: this.reportesAG.esVisible(usuario),
                            routerLink: ['reportes/ingresos-egresos']
                        },
                        {
                            label: this.translateService.get('Ventas'),
                            visible: true,
                            routerLink: ['reportes/ventas'],
                        },
                        {
                            label: this.translateService.get('Compras'),
                            visible: true,
                            routerLink: ['reportes/compras'],
                        },
                        {
                            label: this.translateService.get('Estado de Ventas'),
                            visible: true,
                            routerLink: ['reportes/estado-ventas'],
                        },
                    ],
                },
            ]
        } else {
        }

        return items
    }

    private init() {
        this.novedadService.registrarObservador('usuario', this)
        this.authService.onLogin.subscribe((u) => {
            const menu = this.generarMenu(this.authService.usuario)
            this.updateBS(menu)
        })
        this.translateService.upateData.subscribe((r) => {
            const menu = this.generarMenu(this.authService.usuario)
            this.updateBS(menu)
        })
    }
    private updateBS(menu: MenuItem[]) {
        this.menuBS.next(menu.filter((m) => m.visible))
        var rutas = menu.reduce((flat, toFlat) => flat.concat(toFlat.items ? toFlat.items.filter((i) => !i.items) : [toFlat]), [])
        rutas = rutas.concat([
            {
                label: this.translateService.get('Layout mesas'),
                routerLink: ['mesa/layout'],
            },
        ])
        this.rutasBS.next(rutas)
    }
}
