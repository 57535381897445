<div class="layout-breadcrumb">
    <ul>
        <li>
            <a routerLink="">
                <i class="material-icons">home</i>
            </a>
        </li>
        <li>/</li>
        <ng-template ngFor let-item let-last="last" [ngForOf]="items | async">
            <li>
                <a [routerLink]="item.routerLink" *ngIf="item.routerLink">{{item.label}}</a>
                <ng-container *ngIf="!item.routerLink">{{item.label}}</ng-container>
            </li>
            <li *ngIf="!last">/</li>
        </ng-template>
    </ul>

    <div class="layout-breadcrumb-options">
        <span>{{localSeleccionado?.descripcion}}</span>
        <profile-badge [url]="te.imagenUrl" [class]="'small-badge'" (click)="nuevoPedido(te.id)"
            pTooltip="{{te.descripcion}}" *ngFor="let te of tipoEntregaService?.habilitados | async"></profile-badge>
    </div>
</div>


<p-overlayPanel #op [dismissable]="true">
    <ul class="options">
        <li *ngFor="let lc of localesDisponibles" (click)="aplicarSeleccion(lc)">
            {{lc.descripcion}}
        </li>
    </ul>
</p-overlayPanel>
