import { query } from '@angular/animations';
import { Component, ElementRef, Input, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core'
import { AbstractControl } from '@angular/forms'
import { Subscription } from 'rxjs'
import { Cobranza } from 'src/app/model/Cobranza'
import { Cobro } from 'src/app/model/Cobro'
import { CobroCheque } from 'src/app/model/CobroCheque'
import { CobroMercadoPago } from 'src/app/model/CobroMercadoPago'
import { CobroTarjetaCredito } from 'src/app/model/CobroTarjetaCredito'
import { FormaPago } from 'src/app/model/FormaPago'
import { Moneda } from 'src/app/model/Moneda'
import { CajaService } from 'src/app/services/caja.service'
import { FormaPagoService } from 'src/app/services/forma-pago.service'
import { MonedaService } from 'src/app/services/moneda.service'
import { TipoCuotasTarjetaService } from 'src/app/services/tipo-cuotas-tarjeta.service'
import { TipoTarjetaCreditoService } from 'src/app/services/tipo-tarjeta-credito.service'
import { CobroConCaja } from '../../../model/CobroConCaja'
import { CobroEnEfectivo } from '../../../model/CobroEnEfectivo'
import { FileData } from '../../../model/FileData'
import { FileService } from '../../../services/file.service'
import { Descriptivo } from '../../model/Descriptivo'
import { ErrorHandler } from '../../utils/ErrorsHandler'
import { IConCobros, IValor } from './IConCobros'

@Component({
    selector: 'abm-cobros',
    templateUrl: './abm-cobros.component.html',
    styleUrls: ['./abm-cobros.component.less'],
})
export class AbmCobrosComponent implements OnInit, OnDestroy {
    @ViewChild('inputFile')
    public inputFile: ElementRef
    @ViewChildren('listItem') items: QueryList<ElementRef>;
    public formasPago: FormaPago[] = []
    public loading: boolean = true
    private itemEditado: IValor
    public cajas: Descriptivo[] = []

    private _readonly: boolean = false
    public get readonly(): boolean {
        return this._readonly
    }
    @Input()
    public set readonly(v: boolean) {
        this._readonly = v
    }

    @Input()
    public item: IConCobros

    @Input()
    public local: Descriptivo

    @Input()
    public verMoneda: boolean = false

    @Input()
    public devolucion = false

    private _aDevolver: Cobranza[]
    public get aDevolver(): Cobranza[] {
        return this._aDevolver
    }
    @Input()
    public set aDevolver(v: Cobranza[]) {
        this._aDevolver = v

        if (v?.length) {
            this.aDevolver.forEach((cobranza) => {
                cobranza.cobros.forEach((cobro: Cobro) => {
                    this.agregarCobro(cobro.formaPago,cobro.monto * this.getCotizacion())
                })
            })
        }
    }

    private _monedaRef: Moneda
    public get monedaRef(): Moneda {
        return this._monedaRef
    }
    @Input()
    public set monedaRef(v: Moneda) {
        this._monedaRef = v
    }
    @Input()
    public cotizacion: number = 1

    private sus: Subscription
    @Input()
    public unico: boolean = false
    constructor(
        public formaPagoService: FormaPagoService,
        public monedaService: MonedaService,
        public fileService: FileService,
        public cajasService: CajaService,
        public tipoTarjetaCreditoService: TipoTarjetaCreditoService,
        public tipoCuotasTarjetaService: TipoCuotasTarjetaService,
        private elRef:ElementRef
    ) {}

    ngOnInit() {
        if (!this.monedaRef) this.monedaRef = this.item?.moneda ? this.item.moneda : this.monedaService.default
        this.formaPagoService.getHabilitados().then((r) => {
            this.formasPago = r
        })
        this.sus = this.cajasService.data.subscribe((r) => {
            this.cajas = r.filter((c) => !this.local || c.tieneLocal(this.local))
            this.loading = false
        })
    }
    ngOnDestroy() {
        if (this.sus) this.sus.unsubscribe()
    }

    public adjuntar(item: IValor, event?) {
        event.stopPropagation()
        this.itemEditado = item
        this.inputFile.nativeElement.click()
    }
    handleFileInput(files: FileList) {
        this.fileService.postFile(files[0], 'adjunto').then((r) => {
            this.itemEditado.adjunto = r
        })
    }
    public verAdjunto(file: FileData, event?) {
        window.open(file.url, '_blank')
        event.stopPropagation()
    }
    public quitarAdjunto(item: IValor, event) {
        event.stopPropagation()
        item.adjunto = null
    }
    public getCotizacion() {
        return this.cotizacion && this.cotizacion > 0 ? this.cotizacion : 1
    }
    public agregarCobro(f: FormaPago, valor: number = 0) {
        if (this.readonly) return

        let total = 0
        if (valor) {
            total = valor
        } else if (!this.devolucion) {
            total = this.item.getSaldo() * this.getCotizacion()
        }


        switch (f.codigo) {
            case 'EF':
                this.item.agregarCobro(new CobroEnEfectivo(null, f, null, total, null, this.monedaRef, 1))
                break
            case 'MP':
                // if (this.devolucion) {
                //     this.aDevolver.forEach((cobranza) => {
                //         cobranza.cobros.forEach((cobro: Cobro) => {
                //             if (cobro.tipoCobro == 'M') {
                //                 this.item.agregarCobro(new CobroMercadoPago(null, f, null, cobro.monto * this.getCotizacion(), null, this.monedaRef, 1))
                //             }
                //         })
                //     })
                // } else {
                this.item.agregarCobro(new CobroMercadoPago(null, f, null, total, null, this.monedaRef, 1))
                // }
                break
            case 'TC':
                this.item.agregarCobro(new CobroTarjetaCredito(null, f, null, total, null, this.monedaRef, 1))
                break
            case 'CH':
                this.item.agregarCobro(new CobroCheque(null, f, null, total, null, this.monedaRef, 1))
                break
            default:
                this.item.agregarCobro(new CobroConCaja(null, f, null, total, null, this.monedaRef, 1))
                break
        }
        this.item.valores = [...this.item.valores]
    }

    numberOnly(event): boolean {
        const charCode = event.which ? event.which : event.keyCode
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            return false
        }
        return true
    }

    isValid(): boolean {
        return !this.items?.length || !this.items.some(el => el.nativeElement.getElementsByClassName('ng-invalid').length > 0);
    }

    getErrorMessage(control: AbstractControl) {
        if (control?.errors && (control?.dirty || control?.touched)) {
            return ErrorHandler.getErrorMessage(control)
        }
    }
}
