import { Injectable } from '@angular/core';
import { FiltroMovimiento } from 'src/app/common/model/FiltroMovimiento';
import { DescriptivosService } from 'src/app/common/services/descriptivos.service';
import { MovimientoCaja } from 'src/app/model/MovimientoCaja';

@Injectable({
    providedIn: 'root',
})
export class MovimientoCajaService extends DescriptivosService<MovimientoCaja>{
    public baseName(): string {
        return "movimiento-caja";
    }
    public parseToEnt(data: any): MovimientoCaja {
        return MovimientoCaja.fromData(data);
    }
    public newEnt(): MovimientoCaja {
        return new MovimientoCaja();
    }

    public getSaldos(filtro: FiltroMovimiento = new FiltroMovimiento("")): Promise<SaldosTotales> {
        return this.http.post(this.getBaseURL() + "getSaldoHasta", filtro.json).toPromise().then((s: SaldosTotales) => { return s; });
    }


}

export class SaldosTotales {

    constructor(
        public salidas: number = 0,
        public entradas: number = 0,
        public saldo: number = 0,
    ) {

    }
}
