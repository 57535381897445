import { BehaviorSubject, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { FilterSummary } from './FilterSummary';

export class Filtro {
    public newSearch: boolean = true;
    constructor(
        public name: string,
        public filterMap,
        page: number = 0,
        size: number = 40,
        sortField: string = 'id',
        sortOrder: number = 1,
        guardable: boolean = true
    ) {
       
            this._page = page
            this._size = size
            this._sortField = sortField
            this._sortOrder = sortOrder
            this._guardable = guardable
            setTimeout(() => {
                this.ready = true
            }, 100)
       
        this._currValue = JSON.stringify(this.json)
        this.summary = FilterSummary.parseForm(this, this.filterMap)

        this.saveValue = new BehaviorSubject(this)
        this.valueChange = new BehaviorSubject(this)

        this.subscribeChanges()
    }
    public valueChange: BehaviorSubject<any> = new BehaviorSubject(null)
    public dataChange: BehaviorSubject<any> = new BehaviorSubject(null)
    public saveValue: BehaviorSubject<any> = new BehaviorSubject(null)
    public sub: Subscription
    public isReady: BehaviorSubject<boolean> = new BehaviorSubject(false)
    private _currValue: string = ''
    private _ready: boolean
    public get ready(): boolean {
        return this._ready
    }
    public set ready(v: boolean) {
        this._ready = v
        this.isReady.next(v)
    }
    private _page: number

    private _guardable: boolean = true

    private _layout: string = 'L'
    public get layout(): string {
        return this._layout
    }
    public set layout(v: string) {
        this._layout = v
        this.update(true)
    }
    public setMultiple(data: any, update: boolean = true) {
        var updated = false
        for (const property in data) {
            this['_' + property] = data[property]
            updated = true
        }
        if (updated) this.update(true)
    }
    public get guardable(): boolean {
        return this._guardable
    }

    public forceUpdate() {
        this._currValue = JSON.stringify(this.json)
        this.valueChange.next(this)
    }
    public set guardable(v: boolean) {
        this._guardable = v
        if (this._guardable && !this.sub) {
            this.subscribeChanges()
        }
    }

    private _habilitados: boolean
    public get habilitados(): boolean {
        return this._habilitados
    }
    public set habilitados(v: boolean) {
        if (v == this._habilitados) return
        this._habilitados = v
        this.update(true)
    }

    public _idioma: string
    public get idioma(): string {
        return this._idioma
    }
    public set idioma(v: string) {
        if (v != this._idioma) {
            this._idioma = v
            this.update(true)
        }
    }

    private _sortOrder: number
    public get sortOrder(): number {
        return this._sortOrder
    }
    public set sortOrder(v: number) {
        if (v == this._sortOrder) return
        this._sortOrder = v
        this.update(true)
    }

    private _sortField: string
    public get sortField(): string {
        return this._sortField
    }
    public set sortField(v: string) {
        if (v == this._sortField) return
        this._sortField = v
        this.update(true)
    }

    private _size: number
    public get size(): number {
        return this._size
    }
    public set size(v: number) {
        if (v == this._size) return
        this._size = v
        this.update(true)
    }

    public get page(): number {
        return this._page
    }
    public set page(v: number) {
        if (v == this._page) return
        this._page = v
        this.update(true, false)
    }
    public get paginationPage() {
        return this.size * this.page + 1
    }
    protected onCleanCallback = (f) => {}
    public onClean(f: (f) => void) {
        this.onCleanCallback = f
    }
    protected _searchStr: string
    public count: number = 0
    public summary: FilterSummary[] = []
    public update(triggerChange: boolean = true, resetPage: boolean = true) {
        this.summary = FilterSummary.parseForm(this, this.filterMap)
        if (resetPage) this._page = 0

        if (triggerChange) {
            this.dataChange.next(this)
        }
        this.newSearch = resetPage;
    }

    public get searchStr(): string {
        return this._searchStr
    }
    public set searchStr(v: string) {
        this._searchStr = v
        this.update(true)
    }

    public valid() {
        return true
    }

    public setPage(page, size, sort, sortOrder, update: boolean = false, save: boolean = true) {
        this._page = page
        this._size = size
        this._sortField = sort
        this._sortOrder = sortOrder
        this.update(update, false)
       
    }

    private subscribeChanges() {
        if (this.sub) return
        this.sub = this.dataChange.pipe(filter((v) => v != undefined && this._currValue != JSON.stringify(this.json))).subscribe((v) => {          

            this._currValue = JSON.stringify(this.json)
            this.valueChange.next(this)
        })
    }
    public clean(update: boolean = true) {
        this._searchStr = ''
        this._page = 0
        this._size = 2000
        this._sortField = 'id'
        this._sortOrder = 1
        this._idioma = 'ES'
        this.onCleanCallback(this)
        this.update(update)
    }
    public apply(d: any[]) {
        const searchLike = this.searchStr ? this.searchStr.toUpperCase().split(' ') : null
        return !searchLike ? d : d.filter((value) => !value['descripcion'] || searchLike.every((v) => value['descripcion'].toUpperCase().includes(v)))
    }

    public replacer(key: string, value) {
        if (
            key == 'stateObs' ||
            key == 'storageService' ||
            key == 'isReady' ||
            key == 'onCleanCallback' ||
            key == 'ready' ||
            key == '_currValue' ||
            key == 'dataChange' ||
            key == 'valueChange' ||
            key == 'saveValue' ||
            key == 'sub' ||
            key == 'summary' ||
            key == 'filterMap' ||
            key == 'name' ||
            key == 'count'
        )
            return undefined
        else return value
    }
    public patchValue(v: any, update: boolean = true, resetPage: boolean = true) {
        this._page = v.page ?? this._page ?? 0
        this._size = v.size ?? this._size  ?? 2000
        this._sortField = v.sortField ?? this._sortField
        this._sortOrder = v.sortOrder ?? this._sortOrder
        this._idioma = v.idioma ?? this._idioma
        this._layout = v.layout ?? this._layout ?? 'L'
        this._searchStr = v.searchStr ?? this._searchStr
        // this._guardable = v.guardable;
        this.update(update,   !v.page && resetPage)
    }

    public get json(): any {
        return JSON.parse(JSON.stringify(this, this.replacer).replace(/_/g, ''))
    }

    public hasFilters(){
        return this.summary?.length > 0;
    }
    public unsuscribe(){
        this.valueChange && this.valueChange.complete();
        this.dataChange && this.dataChange.complete();
        this.sub = null;
        this.subscribeChanges();
    }
    clonar() {
        const f = new Filtro(null,null);
         f.patchValue(this,false,false)
         return f;
    }
}

