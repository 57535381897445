
import { Component, OnDestroy, OnInit } from '@angular/core';
import { SessionComponent } from 'src/app/common/components/session-component.component';
import { Filtro } from 'src/app/common/model/Filtro';
import { MessagesService } from 'src/app/common/services/messages-data-service.service';
import { ColumnGenerator } from 'src/app/common/utils/ColumnGenerator';
import { CuentaContable } from 'src/app/model/CuentaContable';
import { CuentaContableService } from 'src/app/services/cuenta-contable.service';

@Component({
	selector: 'listado-cuenta-contable',
	templateUrl: 'listado-cuenta-contable.component.html',
	styleUrls: ['listado-cuenta-contable.component.less']
})

export class ListadoCuentaContableComponent extends SessionComponent implements OnInit, OnDestroy {

	public filtro: Filtro = new Filtro("cuenta-contable_filtros", {}, 0, 10);
	public listado: CuentaContable[] = [];
	public columns = new ColumnGenerator().getColumns(new CuentaContable());
	constructor(messagesService: MessagesService,
		public service: CuentaContableService) {
		super(messagesService)

	}
	ngOnInit() { }
	ngOnDestroy() { }


}