// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    apiUrl: 'https://tucomercio-back.thinkup.com.ar/tucomercio-backend/',
    appURL: 'https://tucomercio-app.thinkup.com.ar/#/',
    tokenKey: 'tucomercio-test',
    googleApiKey: '6Lf1TqsUAAAAACnWY1ryBv7ortfHjJERCLUNF5Ei',
    googleMapsKey: 'AIzaSyAMAvQ4GHdjud6QJ6bAgI_Jr6l79TpRX-s',
    firebase: {
        apiKey: 'AIzaSyDobRICEVe72puE7kcBwxwZMkKytR0GdYk',
        authDomain: 'tucomercio-a536f.firebaseapp.com',
        projectId: 'tucomercio-a536f',
        storageBucket: 'tucomercio-a536f.appspot.com',
        messagingSenderId: '428579641202',
        appId: '1:428579641202:web:6cb9bb87f1a9e93bc1efeb',
        measurementId: 'G-X4NWCF6X3K',
    },
    recaptchaKey: '6LdRwVEbAAAAAPvDSRRJ9nih191sPpDm2inoIOQ2',
    isOverlay: false,
    darkMenu: false,
    defaultEntrega: true,
}

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
