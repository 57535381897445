import { Billete } from 'src/app/model/Billete';



export class BilletesCaja {
  constructor(
    public id?: number,
    public billete?: Billete,
    public cantidad?: number,

  ) {
  }
  public static fromData(data: any): BilletesCaja {
    if (!data) return null;
    const o: BilletesCaja = new BilletesCaja(
      data.id,
      Billete.fromData(data.billete),
      data.cantidad,

    );

    return o;

  }
  public get descripcion() {
    return this.billete ? this.billete.descripcion : "";
  }

}