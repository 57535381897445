import { Injectable } from '@angular/core';
import { ParametricoService } from 'src/app/common/services/parametricos.service';
import { TipoNotificacion } from '../model/TipoNotificacion';



@Injectable()
export class TipoNotificacionService extends ParametricoService<TipoNotificacion>{
    public baseName(): string {
        return "tiponotificacion";
    }
    public newEnt(): TipoNotificacion {
        return new TipoNotificacion();
    }
    public parseToEnt(data: any): TipoNotificacion {
        return TipoNotificacion.fromData(data);
    }
    public parser(e: any): TipoNotificacion {
        return TipoNotificacion.fromData(e);
    }


}