import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Rol } from 'src/app/common/model/Rol';
import { ServicioAbstract } from 'src/app/common/services/service.service';
import { MessagesService } from './messages-data-service.service';

@Injectable()
export class RolService extends ServicioAbstract<Rol> {
    public baseName(): string {
        return "rol";
    }
    public newEnt() {
        return new Rol();
    }
    public parseToEnt(data: any): Rol {
        return Rol.fromData(data);
    }

    protected http: HttpClient
    protected messages: MessagesService;



}